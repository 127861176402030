import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotesPage({ data, location }) {
  const notes = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Notes"
      />
      <h1 className="text-4xl mb-4 text-gray-700 font-semibold">Notes</h1>
      {notes.map(({ node }) => {
        const title = node.frontmatter.title;
        return (
          <article key={node.id} className="mb-10 p-5 border border-gray-300 bg-white">
              <h1 className="heading text-2xl">
                <Link
                  to={node.frontmatter.path}
                  className=""
                >
                  {title}
                </Link>
              </h1>
              <small className="text-gray-600 text-sm">
                {node.frontmatter.date}
              </small>
            <section className="text-base text-gray-800">{node.frontmatter.summary}</section>
          </article>
        );
      })}
    </Layout>
  );
}

export default NotesPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "D MMM, YYYY")
            summary
            title
            path
          }
        }
      }
    }
  }
`;
